import { useQuery } from '@graphcommerce/graphql'
import { ProductInterface } from '@graphcommerce/graphql-mesh'
import { ProductSpecsFragment } from '@graphcommerce/magento-product/components/ProductSpecs/ProductSpecs.gql'
import { useConfigurableContext } from '@graphcommerce/magento-product-configurable'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { ReactNode, useEffect, useMemo } from 'react'
import { LastPriceBreakDocument } from './LastPriceBreak.gql'

export type ProductSpecsProps = ProductSpecsFragment
type AdditionalAttributes = {
  key: string
  value: string | number | ReactNode | null
  label: string
}[]

export function ProductSpecs(
  props: ProductSpecsProps & {
    product?: Pick<ProductInterface, 'production_time' | 'sku' | 'uid'>
  },
) {
  const { aggregations, product } = props
  const { selection, getVariants } = useConfigurableContext(product?.sku || '')
  const selectedVariant = getVariants(selection)
  const filter = ['price', 'category_id', 'size', 'new', 'sale', 'color', 'production_time_bucket']
  const specs = aggregations?.filter(
    (attr) => !filter.includes(attr?.attribute_code ?? '') && attr?.options?.[0]?.value !== '0',
  )

  const additional: AdditionalAttributes = []

  const { data: lastPriceBreak } = useQuery(LastPriceBreakDocument, {
    variables: {
      sku: product?.sku as unknown as string,
    },
    skip: !product || !product.sku,
  })

  const selectedPriceBreak = useMemo(() => {
    const selectedVariantId = selectedVariant.at(0)?.product?.uid
    const currentProduct = lastPriceBreak?.products?.items?.find(
      (item) => item?.uid === product?.uid,
    )
    if (selectedVariantId && currentProduct?.__typename === 'ConfigurableProduct') {
      return currentProduct.variants?.find((variant) => variant?.product?.uid === selectedVariantId)
        ?.product?.last_price_break
    }
    return null
  }, [selectedVariant, lastPriceBreak, product])

  if (selectedPriceBreak) {
    if (selectedPriceBreak.units_per_case) {
      additional.push({
        key: 'case_packing',
        value: i18n._(/* i18n */ '{0} Per case', { 0: selectedPriceBreak.units_per_case }),
        label: i18n._(/* i18n */ 'Case packing'),
      })
    }
    if (
      selectedPriceBreak?.case_height &&
      selectedPriceBreak?.case_weight &&
      selectedPriceBreak?.case_depth
    ) {
      additional.push({
        key: 'case_dimentions',
        value: i18n._(/* i18n */ '{0}" x {1}" x {2}"', {
          0: selectedPriceBreak?.case_height,
          1: selectedPriceBreak?.case_weight,
          2: selectedPriceBreak?.case_depth,
        }),
        label: i18n._(/* i18n */ 'Case dimensions (HxWxD)'),
      })
    }
    if (selectedPriceBreak?.case_weight) {
      additional.push({
        key: 'case_weight',
        value: i18n._(/* i18n */ '{0} lbs', { 0: selectedPriceBreak?.case_weight }),
        label: i18n._(/* i18n */ 'Case weight'),
      })
    }
  }

  if (specs?.length === 0 && additional?.length === 0) return null

  return (
    <div className='mx-[40px]'>
      <h3 className='Type-XXL-Bold'>
        <Trans id='Specifications' />
      </h3>
      <div className='mt-[10px] table w-full border-collapse'>
        <div className='Type-XL-Regular table-header-group'>
          {additional?.map(({ key, value, label }) => (
            <div
              className='table-row border-b-[0.5px] border-b-20-grey last:border-b-[1px]'
              key={key}
            >
              <div className='table-cell px-[17px] py-[10px]'>{label}</div>
              <div className='table-cell px-[17px] py-[10px]'>{value}</div>
            </div>
          ))}
          {specs
            ?.filter(
              (spec) =>
                spec?.attribute_code !== 'category_uid' &&
                spec?.attribute_code !== 'production_time',
            )
            ?.map((aggregation) => (
              <div
                className='table-row border-b-[0.5px] border-b-20-grey first:border-t-[1px] last:border-b-[1px]'
                key={aggregation?.attribute_code}
              >
                <div className='table-cell  px-[17px] py-[10px]'>{aggregation?.label}</div>
                <div className='table-cell px-[17px] py-[10px]'>
                  {aggregation?.options?.map((option, index) => (
                    <>
                      <span key={option?.label}>
                        {option?.label === '1' ? 'Yes' : option?.label}
                      </span>
                      {index !== (aggregation?.options?.length || 0) - 1 && <>, </>}
                    </>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
