/* eslint-disable jsx-a11y/alt-text */
import { useMutation } from '@graphcommerce/graphql'
import { Image } from '@graphcommerce/image'
import { i18n } from '@lingui/core'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { GraphQLError } from 'graphql'
import { useState } from 'react'
import expandIcon from '../../assets/images/arrow-down.svg'

import { RateAmFaqQuestionDocument } from '../Product/ProductFAQs/RateAmFaqQuestion.gql'
import { AlertPrompt } from '../Prompt/AlertPrompt'
import { ErrorPrompt } from '../Prompt/ErrorPrompt'

export type Answers = {
  content: string
}
export type QuestionAndAnswerItemProps = {
  email: string
  name: string
  short_answer: string
  title: string
  negative_rating: number
  positive_rating: number
  customer: any | undefined
  question_id: number
}

export function QuestionAndAnswerItem(props: QuestionAndAnswerItemProps) {
  const {
    email,
    name,
    short_answer,
    title,
    negative_rating,
    positive_rating,
    customer,
    question_id,
  } = props
  const isUserLoggedIn = !!customer

  const [votesUp, setVotesUp] = useState<number>(positive_rating)
  const [hasLeftVoteUp, setHasLeftVoteUp] = useState<boolean>(false)
  const [hasLeftVoteDown, setHasLeftVoteDown] = useState<boolean>(false)

  const [votesDown, setVotesDown] = useState<number>(negative_rating)
  const [isDisplayAlertPrompt, setisDisplayAlertPrompt] = useState<boolean>(false)
  const [isDisplayErrorPrompt, setIsDisplayErrorPrompt] = useState<boolean>(false)
  const [error, setError] = useState<Error>()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const [rateAmFaqQuestion] = useMutation(RateAmFaqQuestionDocument)

  const voteProductFAQ = async (isVoteUp: boolean, isRevote?: boolean) => {
    setIsSubmitting(true)
    // eslint-disable-next-line no-nested-ternary
    const oldValue = isRevote ? (isVoteUp ? { old_value: '' } : { old_value: 'YES' }) : undefined

    const res = await rateAmFaqQuestion({
      variables: {
        is_revote: isRevote,
        question_id,
        value: isVoteUp ? 'YES' : '',
        vote_type: 'YESNO',
        ...oldValue,
      },
    })
      .catch((voteError: Error) => {
        setError(voteError)
        setIsDisplayErrorPrompt(true)
      })
      .finally(() => {
        setHasLeftVoteUp(true)
        setIsSubmitting(false)
      })
    if (res?.data?.rateAmFaqQuestion?.error === true) {
      setError(new GraphQLError(res?.data?.rateAmFaqQuestion?.message ?? ''))
    }
  }

  const handleVoteUp = async () => {
    if (!isUserLoggedIn) {
      setIsDisplayErrorPrompt(true)
      return
    }
    if (!hasLeftVoteUp && !hasLeftVoteDown) {
      await voteProductFAQ(true, false)
      if (!error) {
        setHasLeftVoteUp(true)
        setVotesUp(votesUp + 1)
        setisDisplayAlertPrompt(true)
      }
    }
    if (hasLeftVoteDown) {
      await voteProductFAQ(true, true)
      if (!error) {
        setHasLeftVoteDown(false)
        setHasLeftVoteUp(true)
        setVotesUp(votesUp + 1)
        setVotesDown(votesDown - 1)
        setisDisplayAlertPrompt(true)
      }
    }
  }

  const handleVoteDown = async (isRevote?: boolean) => {
    if (!isUserLoggedIn) {
      setIsDisplayErrorPrompt(true)
      return
    }
    if (!hasLeftVoteUp && !hasLeftVoteDown) {
      await voteProductFAQ(false, false)
      if (!error) {
        setHasLeftVoteDown(true)
        setVotesDown(votesDown + 1)
        setisDisplayAlertPrompt(true)
      }
    }
    if (hasLeftVoteUp) {
      const res = await voteProductFAQ(false, true)
      if (!error) {
        setHasLeftVoteDown(true)
        setHasLeftVoteUp(false)
        setVotesDown(votesDown + 1)
        setVotesUp(votesUp - 1)
        setisDisplayAlertPrompt(true)
      }
    }
  }

  return (
    <div className='mb-[20px]'>
      <Accordion elevation={3}>
        <AccordionSummary
          expandIcon={
            <Image
              src={expandIcon}
              alt='expand or close'
              layout='fixed'
              unoptimized
              height={40}
              width={40}
            />
          }
          className='Type-XXL-Regular'
        >
          <h3 className='Type-XXL-Regular'>{title}</h3>
        </AccordionSummary>
        <AccordionDetails className='m-[10px] bg-lightest-gray/70 px-[40px] py-[10px] md:m-[20px] md:mt-0'>
          <h3 className='font-[17px]'>{short_answer}</h3>
        </AccordionDetails>
      </Accordion>
      {/* <div className='flex items-center pt-2'>
        <span className='Type-XL-Bold inline-block items-center pr-2 text-50-grey'>
          {i18n._('Was this question and answer helpful?')}
        </span>
        {isSubmitting ? (
          <Spinner className='' color='purple' />
        ) : (
          <Image
            src={recommend}
            alt='Recommend'
            className='scale-75 cursor-pointer hover:scale-90 active:scale-100'
            unoptimized
            onClick={() => handleVoteUp()}
          />
        )}
        <span className='pl-[4px] pr-3 text-70-grey'>{votesUp}</span>
        {isSubmitting ? (
          <Spinner className='' color='purple' />
        ) : (
          <Image
            className='scale-75 cursor-pointer hover:scale-90 active:scale-100'
            src={notRecommend}
            alt='Not Recommend'
            unoptimized
            onClick={() => handleVoteDown()}
          />
        )}
        <span className='pl-[4px] text-70-grey'>{votesDown}</span>
      </div> */}
      <AlertPrompt
        title={i18n._('Thank you')}
        isDisplayed={isDisplayAlertPrompt}
        setIsDisplayed={setisDisplayAlertPrompt}
        message={i18n._('Thank you for leaving a feedback!')}
      />
      <AlertPrompt
        title={i18n._('Oops!')}
        isDisplayed={isDisplayErrorPrompt}
        setIsDisplayed={setIsDisplayErrorPrompt}
        message={i18n._('You must be logged in to leave a feedback for a question and answer.')}
      />
      <ErrorPrompt error={error} />
    </div>
  )
}
