/* eslint-disable @next/next/no-img-element */
import { Image } from '@graphcommerce/image'
import { ProductPageGalleryFragment } from '@graphcommerce/magento-product/components/ProductPageGallery/ProductPageGallery.gql'
import { nonNullable, SidebarGalleryProps, TypeRenderer } from '@graphcommerce/next-ui'
import { Paper } from '@mui/material'
import {
  ButtonBack,
  ButtonNext,
  CarouselContext,
  CarouselProvider,
  DotGroup,
  Slide,
  Slider,
} from 'pure-react-carousel'
import { useContext, useEffect, useMemo, useState } from 'react'
import arrowLeft from '../../assets/images/arrow-left-outline.svg'
import arrowRight from '../../assets/images/arrow-right-outline.svg'
import { Icon } from '../Layout/Icon'

export type ProductPageGalleryRenderers = TypeRenderer<
  NonNullable<NonNullable<ProductPageGalleryFragment['media_gallery']>[0]>
>

export type ProductPageGalleryProps = ProductPageGalleryFragment &
  Omit<SidebarGalleryProps, 'sidebar' | 'images'> & { isMobile: boolean }

export function CarouselStateRaiser({ onSlideChange }: { onSlideChange: (index: number) => void }) {
  const carouselContext = useContext(CarouselContext)
  useEffect(() => {
    function onChange() {
      onSlideChange(carouselContext.state.currentSlide)
    }
    carouselContext.subscribe(onChange)
    return () => carouselContext.unsubscribe(onChange)
  }, [carouselContext, onSlideChange])
  return null
}

export function ProductPageGallery(props: ProductPageGalleryProps) {
  const { media_gallery, aspectRatio: [width, height] = [1532, 1678], isMobile } = props

  const [currentSlide, setCurrentSlide] = useState(0)

  const images = useMemo(
    () =>
      media_gallery
        ?.filter(nonNullable)
        .sort((a, b) => (a.position ?? 0) - (b.position ?? 0))
        .map((item) => {
          if (item.__typename === 'ProductImage')
            return { src: item.url ?? '', alt: item.label || undefined, width, height }
          return {
            src: '',
            alt: `{${item.__typename} not yet supported}`,
          }
        }) ?? [],
    [media_gallery, width, height],
  )

  return (
    <div className='flex w-full flex-col items-center justify-center'>
      <div className='m-[20px] grid w-full md:w-[450px] lg:w-[400px] xl:w-[600px]'>
        <CarouselProvider
          naturalSlideWidth={1.5}
          naturalSlideHeight={1}
          totalSlides={images.length}
          currentSlide={currentSlide}
          infinite
          className='h-auto md:h-[450px] md:max-h-[450px] lg:h-[400px] lg:max-h-[400px] xl:h-[600px] xl:max-h-[600px]'
        >
          <Slider className='block h-auto w-full text-center md:h-[450px] md:max-h-[450px] lg:h-[400px] lg:max-h-[400px] xl:h-[600px] xl:max-h-[600px]'>
            <CarouselStateRaiser onSlideChange={setCurrentSlide} />
            {images.map((image, index) => (
              <Slide
                index={index}
                className='h-auto md:h-[450px] md:max-h-[450px] lg:h-[400px] lg:max-h-[400px] xl:h-[600px] xl:max-h-[600px]'
              >
                <div
                  key={image.src}
                  className='flex aspect-square h-full w-full cursor-pointer items-center justify-center overflow-hidden shadow-[20px] md:h-[450px] md:max-h-[450px] lg:h-[400px] lg:max-h-[400px] xl:h-[600px] xl:max-h-[600px]'
                  role='button'
                  tabIndex={index}
                >
                  <img
                    src={image.src}
                    alt={image.alt}
                    className='aspect-square h-full w-auto object-cover shadow-[20px] md:h-[450px] md:max-h-[450px] lg:h-[400px] lg:max-h-[400px] xl:h-[600px] xl:max-h-[600px]'
                  />
                </div>
              </Slide>
            ))}
          </Slider>
          {isMobile && (
            <DotGroup
              className='mt-3 flex w-full justify-center gap-2 xs:flex sm:hidden'
              renderDots={(dotProps) =>
                images.map((image, i) => (
                  <Icon
                    key={image.src}
                    className='cursor-pointer text-material-ui-blue hover:text-material-ui-dark-blue'
                    name={
                      i === dotProps.currentSlide
                        ? 'radio_button_checked'
                        : 'radio_button_unchecked'
                    }
                    onClick={() => setCurrentSlide(i)}
                  />
                ))
              }
            />
          )}
        </CarouselProvider>
      </div>
      {!isMobile && (
        <div className='mx-[20px] hidden w-full items-center justify-center md:flex md:w-[450px]'>
          <CarouselProvider
            naturalSlideWidth={3}
            naturalSlideHeight={1}
            totalSlides={images.length}
            visibleSlides={4}
            infinite
            isIntrinsicHeight
            className='mb-[20px] grid w-full grid-cols-[5fr_90fr_5fr] gap-[10px] self-center'
          >
            <ButtonBack className='z-10 flex items-center justify-center justify-self-start hover:scale-110'>
              <Image src={arrowLeft} alt='Back' layout='fixed' unoptimized height={20} />
            </ButtonBack>
            <Slider className=''>
              {images?.map((image, index) => (
                <div
                  className={`mx-[5px] flex max-h-full max-w-full cursor-pointer items-center justify-center overflow-hidden border-[1px] border-light-gray shadow-md ${
                    index === currentSlide ? 'shadow-lg shadow-20-grey' : ''
                  }`}
                  tabIndex={0}
                  onClick={() => setCurrentSlide(index)}
                  onKeyDown={() => setCurrentSlide(index)}
                  role='button'
                >
                  <Image
                    src={image.src}
                    alt={image.alt}
                    width={image.width}
                    height={image.height}
                    layout='responsive'
                    className='h-auto w-full object-cover'
                    sx={{
                      objectFit: 'cover',
                      height: 'auto',
                      width: '100%,',
                      maxHeight: '60px',
                      aspectRatio: '1 / 1',
                    }}
                  />
                </div>
              ))}
            </Slider>
            <ButtonNext className='z-10 flex items-center justify-center justify-self-end hover:scale-110'>
              <Image src={arrowRight} alt='Back' layout='fixed' unoptimized height={20} />
            </ButtonNext>
          </CarouselProvider>
        </div>
      )}
    </div>
  )
}
